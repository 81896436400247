import moment from "moment-timezone";

/**
 * Send a request to the specified URL from a form. This will change the window location.
 *
 * @param {string} path the path to send the post request to
 * @param {object} params the paramiters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
export function browserHttpRequest(path, params, method = "post") {
  const form = document.createElement("form");
  form.method = method;
  form.action = path;

  params["_token"] = document.getElementsByName("csrf-token")[0].getAttribute("content");

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

/**
 * Format a date using localized formats. The values (100, 200...) are retrieved
 * from the database and set as a global "localizedDateFormats" object inside a
 * script tag in every Blade view, using the <x-javascript-dates /> component.
 *
 * WARNING!!!: if inputDate is a string without offset, eg: 2022-05-25 15:36:22,
 * the timeZone is used to determine in which zone that time is. If inputDate
 * contains an offset instead, eg: 2022-05-25T15:36:22.000000Z, the parsed
 * inputDate will be converted in the given timeZone.
 */
export function formatDate(inputDate, format, timeZone) {
  const date = moment.tz(inputDate, timeZone);

  // Check if "format" is one of the supported ones.
  if (window.localizedDateFormats.hasOwnProperty(format)) {
    let formatted = date.format(window.localizedDateFormats[format]);

    return formatted.charAt(0).toUpperCase() + formatted.slice(1);
  }

  // Otherwise, directly format using Moment formats.
  return date.format(format);
}

export function generateSrcset(image_sizes) {
  let value = [];
  for (const [key, image] of Object.entries(image_sizes)) {
    value.push(image.uri + " " + image.width + "w");
  }
  return value.join(", ");
}

/**
 * Function to generate an array of consecutive integers between two ranges
 * @param {number} start
 * @param {number} end
 * @returns {Array.<number>}
 */
export function range(start, end) {
  if (start === end) return [start];

  if (end < start) {
    return [start, ...range(start - 1, end)];
  } else {
    return [start, ...range(start + 1, end)];
  }
}
